import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';

import ColorBlock from 'components/Content/ColorBlock';
import AlertBox from 'components/Content/AlertBox';

import NDHCPNSSHeroMobile from '../../../assets/images/ND-HCP-NascobalNS-mobile.png';

import meanImage from '../../../assets/images/vitaminb12-mean-concentration.png';
import step1 from '../../../assets/images/nasal-spray-step1-hcp.png';
import step2 from '../../../assets/images/nasal-spray-step2-hcp.png';
import step3 from '../../../assets/images/nasal-spray-step3-hcp.png';
import './nasal-spray.scss';

let Subscription = null;

const nasalsprayPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionHcp;
  // const metaData = appConfigs?.metaData?.nutritionHcp;

  const brandNames = {
    nascobal: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | NASCOBAL® Nasal Spray';
  const pageDescription = 'What is NASCOBAL® Nasal Spray?';

  const renderNasalSpray = () => {
    return (
      <div>
        <ContentBlock className="no-padding-top">
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    <span
                      className="brandname-nascobal txt-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    <br className="hide-only-mobile" />
                    nasal spray
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDHCPNSSHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>
                <ColorBlock white>
                  <h2 className="strong">
                    The first FDA-approved prescription vitamin B<sub>12</sub>{' '}
                    nasal spray<sup>1</sup>
                  </h2>
                  <p className="strong">
                    A well-established way to deliver vitamin B<sub>12</sub>
                  </p>
                  <ul>
                    <li>Intranasal delivery</li>
                    <li>
                      Designed to bypass the gastrointestinal (GI) tract and
                      deliver vitamin B<sub>12</sub> directly into the
                      bloodstream through the nasal mucosa<sup>2</sup>
                    </li>
                    <li>Not a painful injection</li>
                    <li>
                      Clinically proven to have increased and maintained vitamin
                      B<sub>12</sub> levels<sup>3</sup>
                    </li>
                    <li>
                      Convenient, once-weekly dosing regimen<sup>4</sup>
                    </li>
                    <li>Self-administered, tasteless and odorless fine mist</li>
                    <li className="blue strong">
                      1 spray, 1 nostril, 1x a week<sup>4</sup>
                    </li>
                    <li>
                      Eligible patients may pay as little as $0 per month*
                    </li>
                  </ul>
                  <p className="footnote asterik bottom-spacer">
                    Patients may redeem this offer ONLY when accompanied by a
                    valid prescription. Offer is valid up to a maximum benefit
                    of $150. Offer is not valid for patients whose prescriptions
                    are reimbursed in whole or in part under Medicaid, Medicare,
                    Medigap, VA, DoD, TRICARE, or any other federal or state
                    programs (such as medical assistance programs) or where
                    otherwise prohibited by law. Offer is not valid in VT or
                    where prohibited in whole or in part. This offer may be
                    amended or ended at any time without notice.
                  </p>
                </ColorBlock>
              </Col>
              <Col xs={12} md={7} className="mean-chart-block">
                <ColorBlock gray>
                  <h2 className="strong">
                    Clinically proven to have increased vitamin B<sub>12</sub>{' '}
                    levels from the first dose<sup>3</sup>
                  </h2>
                  <img src={meanImage} alt="vitaminb12-mean-concentration" />
                  <ul>
                    <li>
                      Achieved mean peak serum vitamin B<sub>12</sub>{' '}
                      concentration within 1.5 hours<sup>3&dagger;</sup>
                    </li>
                    <li>
                      Reached mean peak concentration of 1021 pg/mL
                      <sup>3&dagger;</sup>
                    </li>
                    <li>
                      At <span className="orange strong">72 hours</span>, mean
                      serum vitamin B<sub>12</sub> level was increased by{' '}
                      <span className="orange strong">46%</span> above baseline
                      <sup>3&dagger;</sup>
                    </li>
                  </ul>
                  <p className="footnote dagger bottom-spacer">
                    In 21 healthy volunteers under fasting conditions, a single
                    500 mcg dose of{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    was given and monitored for 3&nbsp;days. Data are based on
                    baseline uncorrected serum vitamin B<sub>12</sub> levels.
                  </p>
                </ColorBlock>
              </Col>
            </Row>
            <ColorBlock white>
              <Row>
                <Col xs={12}>
                  <h2 className="pt-10 strong">
                    How to use{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    Nasal Spray
                  </h2>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock blue id="colorblock-nasal-spray">
              <Row>
                <Col xs={12} md={4}>
                  <img
                    src={step1}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step1-hcp"
                  />
                  <ul>
                    <li>
                      The unit contains only 1 spray:
                      <br />
                      <strong>DO NOT prime before use</strong>
                    </li>
                    <li>Blow nose gently to clear both nostrils</li>
                    <li>
                      Hold the unit with your thumb, supporting it at the
                      bottom, and your index and middle fingers on either side
                      of the nozzle
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={step2}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step2-hcp"
                  />
                  <ul>
                    <li>
                      Gently close 1 nostril with your other index finger.
                      Insert the nozzle into open nostril approximately ½ inch,
                      or as far as feels comfortable, and tilt your head
                      slightly forward. Do not press the plunger yet{' '}
                    </li>
                    <li>
                      Breathe in gently through your nose, close your mouth, and
                      at the same time, press the plunger firmly with your thumb
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={step3}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step3-hcp"
                  />
                  <ul>
                    <li>
                      Remove the nozzle from your nostril. At the same time,
                      keep your head level for 10 to 20 seconds while gently
                      breathing in through your nose and breathing out through
                      your mouth
                    </li>
                  </ul>
                </Col>
              </Row>
              <AlertBox>
                <span
                  className="brandname-nascobal"
                  dangerouslySetInnerHTML={{
                    __html: brandNames.nascobal,
                  }}
                />{' '}
                Nasal Spray should be administered at least one hour before or
                one hour after ingestion of hot foods or liquids.
                <p className="blue">
                  <strong>Dose adjustments may be required.</strong>
                </p>
              </AlertBox>
            </ColorBlock>
          </Grid>
        </ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/why-nutrition-direct"
              LinkCaption="WHY NUTRITION DIRECT™"
            >
              WHY{' '}
              <span
                className="brandname-nutrition txt-uppercase"
                dangerouslySetInnerHTML={{
                  __html: brandNames.NUTRITION,
                }}
              />
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/recipes"
              LinkCaption="GET HEALTHY RECIPES"
            >
              GET HEALTHY RECIPES
            </BrandCTA>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-hcp-nasal-spray">
        {renderNasalSpray()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="nutrition-hcp-nasal-spray-page">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default nasalsprayPage;
